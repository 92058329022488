export enum ComponentDataType {
  MAIN_SECTION = 'TPA',
  MULTI_SECTION = 'TPAMultiSection',
  WIDGET = 'TPAWidget',
}

export enum ComponentType {
  COMPONENT = 'Component',
  PAGE = 'Page',
  CONTAINER = 'Container',
}

export interface ComponentStructure {
  id: string;
  style: any;
  data: {
    appDefinitionId?: string
    applicationId: string
    id: string
    metaData: {isPreset: boolean, schemaVersion: string, isHidden: boolean}
    type: string
    widgetId: string
    controllerType?: string
    name?: string
    settings?: any
  }
}

export interface GeneralReference {
  id: string;
  type: string;
}
