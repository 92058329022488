import { BOOKINGS_DEF_ID, MEMBERS_AREA_DEF_ID } from '../constants';

export async function installMembersArea(sdk, appToken) {
  sdk.application.install(appToken, {
    appDefinitionId: MEMBERS_AREA_DEF_ID,
    initiatorAppDefinitionId: BOOKINGS_DEF_ID
  })
}

export function addBookingsMembersArea(sdk, appToken) {
  const membersAPI = sdk.application.getPublicAPI(appToken, {appDefinitionId: MEMBERS_AREA_DEF_ID});
  return membersAPI.addSection({
    appDefinitionId: BOOKINGS_DEF_ID,
    pageId: 'bookings_member_area',
    social: false,
    showInLoginMenu: true
  });
}
